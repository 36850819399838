import React, { useEffect } from 'react';
import { oneOfType, node, elementType, shape } from 'prop-types';
import getConfig from 'next/config';
import { ThemeProvider } from '@magalu/stereo-ui-styles';
import { userTrack, whereAmI } from '@magalu/mixer-utils';

const getCookieDomain = siteId => {
  const config = getConfig();
  const cookieDomain = config?.publicRuntimeConfig?.cookieDomain;

  return cookieDomain[siteId] || cookieDomain.default;
};

const Mixer = ({ Component, pageProps }) => {
  useEffect(() => {
    if (whereAmI.onClient) {
      const cookieDomain = getCookieDomain(pageProps?.structure?.site);
      userTrack.generateTrackId(cookieDomain);
    }
  }, []);

  return (
    <ThemeProvider theme={pageProps?.structure?.theme}>
      <Component {...pageProps} />
    </ThemeProvider>
  );
};

Mixer.propTypes = {
  Component: oneOfType([node, elementType]).isRequired,
  pageProps: shape({}),
};

Mixer.defaultProps = {
  pageProps: {},
};

export default Mixer;
